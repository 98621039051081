.aws-btn {
  --button-default-height: 65px;
  --button-default-font-size: 20px;
  --button-default-border-radius: 25px;
  --button-horizontal-padding: 50px;
  --button-raise-level: 5px;
  --button-hover-pressure: 1.5;
  --transform-speed: 0.15s;
  --button-primary-color: #846bb9;
  --button-primary-color-dark: #604e88;
  --button-primary-color-light: #ffffff;
  --button-primary-color-hover: #856cbb;
  --button-primary-color-active: #846bb9;
  --button-primary-border: none;
}
