.team-member-container {
  height: 400px;
  width: 400px;
}

arrow {
  text-align: center;
  margin: 8% 0;
}

.bounce {
  -moz-animation: bounce 2s infinite;
  -webkit-animation: bounce 2s infinite;
  animation: bounce 2s infinite;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
      transform: translateY(0);
  }
  40% {
      transform: translateY(-30px);
  }
  60% {
      transform: translateY(-15px);
  }
}

@media screen and (max-width: 900px) {
  .team-member-container {
      height: 375px;
      width: 375px;
  }
  .banner-2-up {
      bottom: 61%;
  }
}

@media screen and (max-width: 830px) {
  .team-member-container {
      height: 350px;
      width: 350px;
  }
  .banner-2-up {
      bottom: 60%;
  }
}

@media screen and (max-width: 780px) {
  .team-member-container {
      height: 325px;
      width: 325px;
  }
  .banner-2-up {
      bottom: 58%;
  }
}

@media screen and (max-width: 767px) {
  .team-member-container {
      height: 500px;
      width: 500px;
  }
  .banner-2-up {
      bottom: 70%;
  }
  .banner-1-up {
      bottom: 84%;
  }
}

@media screen and (max-width: 639px) {
  .banner-1-down h3 {
      font-size: 1.5rem;
  }
  .banner-1-up h3 {
      font-size: 1.5rem;
  }
  .banner-2-up {
      bottom: 70%;
  }
}

@media screen and (max-width: 509px) {
  .team-member-container {
      height: 450px;
      width: 450px;
  }
  .banner-1-down h3 {
      font-size: 1.5rem;
  }
  .banner-1-up h3 {
      font-size: 1.5rem;
  }
  .banner-2-up {
      bottom: 68%;
  }
  .img-small {
      top: 8px;
  }
}

@media screen and (max-width: 466px) {
  .team-member-container {
      height: 400px;
      width: 400px;
  }
  .banner-1-down h3 {
      font-size: 1.5rem;
  }
  .banner-1-up h3 {
      font-size: 1.5rem;
  }
  .banner-2-up {
      bottom: 66%;
  }
  .img-small {
      top: 0px;
  }
}

@media screen and (max-width: 420px) {
  .team-member-container {
      height: 375px;
      width: 375px;
  }
  .banner-1-up {
      bottom: 79%;
  }
  .banner-2-up {
      bottom: 60%;
  }
  .img-small {
      top: 15px;
  }
}

@media screen and (max-width: 400px) {
  .team-member-container {
      width: 350px;
      height: 350px;
  }
  .banner-1-up {
      bottom: 79%;
  }
  .banner-2-up {
      bottom: 59%;
  }
}

@media screen and (max-width: 370px) {
  .team-member-container {
      height: 325px;
      width: 325px;
  }
  .description-container--inner {
      margin-top: 10px;
  }
  .banner-1-up {
      bottom: 76%;
  }
  .banner-2-up {
      bottom: 55%;
  }
}

@media screen and (max-width: 347px) {
  .team-member-container {
      height: 300px;
      width: 300px;
  }
  .img-small {
      display: none;
  }
  .banner-1-up {
      padding-left: 12px;
      font-size: 15px;
  }
  .banner-1-down {
      padding-left: 12px;
      font-size: 15px;
  }
  .banner-2-up {
      bottom: 53%;
  }
}

@media screen and (max-width: 320px) {
  .team-member-container {
      width: 275px;
      height: 275px;
  }
  .banner-1-up {
      bottom: 74%;
  }
  .banner-2-up {
      bottom: 49%;
  }
}

@media screen and (max-width: 295px) {
  .team-member-container {
      width: 250px;
      height: 250px;
  }
  .description-container {
      padding: 35% 5px 10px 5px;
  }
  .description-container--inner {
      margin-top: 0px;
      font-size: 15px;
  }
  .banner-2-up {
      bottom: 46%;
      left: -5px;
  }
  .banner-2-down {
      left: -6px;
  }
  .arrow {
      display: none;
  }
}

@media screen and (max-width: 270px) {
  .team-member-container {
      width: 200px;
      height: 200px;
  }
  .banner-2-down {
      width: 100px;
      left: -5px;
      bottom: 19%;
  }
  .banner-2-up {
      width: 100px;
      bottom: 35px;
      left: -5px;
      bottom: 56%;
  }
  .banner-1-down h3 {
      font-size: 15px;
  }
  .banner-1-up h3 {
      font-size: 15px;
  }
  .banner-1-down p {
      font-size: 11px;
  }
  .banner-1-up p {
      font-size: 11px;
  }
  .description-container {
      padding-top: 36%;
  }
}