div.scroll-style::-webkit-scrollbar {
  width: 8px;
}

div.scroll-style::-webkit-scrollbar-track {
  background: none;
}

div.scroll-style::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 50px;
}

div.scroll-style::-webkit-scrollbar-thumb:hover {
  background: #555;
}
