.sport-info-container {
  margin-bottom: 230px;
  margin-top: 100px;
}

@media screen and (max-width: 1160px) {
  .sport-image {
    right: -2%;
  }
}
@media screen and (max-width: 1000px) {
  .text-container {
    margin-right: 51%;
  }
  .animated--hips {
    width: 300px;
    height: 300px;
  }
}

@media screen and (max-width: 870px) {
  .sport-image {
    position: relative;
    width: 100vw;
    height: auto;
    margin: 0 auto 20px auto;
    top: 0px;
    left: 0;
  }
  .text-container {
    margin-right: 0;
  }
  .sport-info-container {
    margin-bottom: 50px;
    margin-top: 0;
  }
}
