.top-info-container {
  margin-bottom: 330px;
}

@media screen and (max-width: 1160px) {
  .massage-image {
    top: -30px;
    right: 15px;
  }
  .text-container {
    margin-right: 38%;
  }
}

@media screen and (max-width: 910px) {
  .massage-image {
    top: -30px;
    right: 15px;
    width: 100vw;
    height: auto;
  }
  .text-container {
    margin-right: 40%;
  }
}

@media screen and (max-width: 870px) {
  .massage-image {
    position: relative;
    margin-top: 50px;
  }
  .text-container {
    margin-right: 0;
  }
  .top-info-container {
    margin-bottom: 100px;
  }
}
