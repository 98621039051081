.table-links {
  width: 300px;
  min-height: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 10px 0 10px;
  background-color: white;
  border-bottom: 1px solid rgb(112, 112, 112);
  color: #846bb9;
  position: relative;
  transition-timing-function: ease-in-out;
  transition-duration: 300ms;
  transition-property: all;
}

.table-links:hover {
  background-color: rgb(235, 235, 235);
  color: black;
  transition-timing-function: ease-in-out;
  transition-duration: 300ms;
  transition-property: all;
}

.active {
  background-color: #846bb9;
  color: white;
}
