/* Add this to your CSS or in a style block */
@keyframes sparklingBackground {
  0% {
    background-color: #846bb9;
  }
  50% {
    background-color: rgb(144, 115, 192);
  }
  100% {
    background-color: #846bb9;
  }
}

/* Apply the animation to your existing class */
.sparkling-background {
  animation: sparklingBackground 3s infinite;
}
