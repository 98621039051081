@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;1,100;1,300;1,400;1,500&display=swap');

@font-face {
  font-family: 'abeezeeregular';
  src: url('./assets/fonts/abeezee-regular-webfont.woff2') format('woff2'),
    url('./assets/fonts/abeezee-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'fontello';
  src: url('./assets/fonts/fontello/fontello.eot');
  src: url('./assets/fonts/fontello/fontello.eot') format('embedded-opentype'),
    url('./assets/fonts/fontello/fontello.woff') format('woff'),
    url('./assets/fonts/fontello/fontello.ttf') format('truetype'),
    url('./assets/fonts/fontello/fontello.svg') format('svg');
}

body {
  margin: 0;
  font-family: 'abeezeeregular', sans-serif !important;
  font-weight: 200 !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.font-fontello {
  font-family: 'fontello';
  font-weight: 100;
}

.font-abz--100 {
  font-family: 'abeezeeregular', arial, sans-serif;
  font-weight: 100;
}

.font-abz--200 {
  font-family: 'abeezeeregular', arial, sans-serif;
  font-weight: 200;
}

.font-abz--300 {
  font-family: 'abeezeeregular', arial, sans-serif;
  font-weight: 300;
}

.font-abz--400 {
  font-family: 'abeezeeregular', arial, sans-serif;
  font-weight: 400;
}

.font-abz--500 {
  font-family: 'abeezeeregular', arial, sans-serif;
  font-weight: 500;
}

.font-abz--600 {
  font-family: 'abeezeeregular', arial, sans-serif;
  font-weight: 600;
}

::selection {
  background: #846bb9; /* WebKit/Blink Browsers */
  color: white; /* WebKit/Blink Browsers */
}
::-moz-selection {
  background: #846bb9; /* Gecko Browsers */
}

.animated-cust > * {
  transition-timing-function: ease-in-out;
  transition-duration: 300ms;
  transition-property: all;
}
