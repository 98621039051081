.pregnancy-info-container {
  margin-bottom: 330px;
  margin-top: 100px;
}

.animated--hips {
  width: 320px;
  height: 320px;
  position: absolute;
  left: 0;
}

@media screen and (max-width: 1160px) {
  .pregnancy-image {
    right: 1%;
  }
  /* .text-container {
    margin-right: 38%;
  } */
}
@media screen and (max-width: 1000px) {
  .text-container {
    margin-right: 51%;
  }
  .animated--hips {
    width: 300px;
    height: 300px;
  }
}

@media screen and (max-width: 929px) {
  .animated--hips {
    width: 250px;
    height: 250px;
    left: -46px;
  }
}

@media screen and (max-width: 910px) {
  .pregnancy-image {
    top: -220px;
  }
}

@media screen and (max-width: 870px) {
  .pregnancy-image {
    position: relative;
    width: 100vw;
    height: auto;
    margin: 0 auto 20px auto;
    top: 0px;
  }
  .text-container {
    margin-right: 0;
  }
  .pregnancy-info-container {
    margin-bottom: 100px;
    margin-top: 0;
  }
  .animated--hips {
    top: 10%;
    left: 5%;
    width: 325px;
    height: 325px;
  }
}

@media screen and (max-width: 800px) {
  .animated--hips {
    display: none;
  }
}
